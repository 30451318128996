import React from 'react';
import SwiperCore, { Grid, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import LeftArrow from '../../../../../../images/v5/Launch/Carousel-Left.svg';
import RightArrow from '../../../../../../images/v5/Launch/Carousel-Right.svg';

// import 'swiper/css/bundle';
import { shuffle } from 'lodash';
import 'swiper/css';
import 'swiper/css/grid';

SwiperCore.use([Pagination, Grid]);

export default function CarouselV2(props) {
  const swiperRef = React.useRef(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const settings = {
    id: 'launchExternshipProjects',
    slidesPerView: 1,
    slidesPerGroup: 1,

    breakpoints: {
      640: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
    },
    loop: true,
    speed: 500,
    grabCursor: true,
    loopFillGroupWithBlank: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    allowTouchMove: true,
  };

  // ,
  const slides = [];
  let shuffledSlides = shuffle(props.data);
  for (let i = 0; i < shuffledSlides.length; i += 1) {
    slides.push(
      <SwiperSlide
        className="flex min-h-[380px] justify-center sm:h-[420px]"
        key={i}
      >
        <Card nestedData={shuffledSlides[i]} />
      </SwiperSlide>,
    );
  }

  return (
    slides.length > 0 && (
      <>
        <div className="relative">
          <div className="mx-8 sm:mx-10">
            <Swiper
              {...settings}
              ref={swiperRef}
              className="pb-8"
              pagination={{
                clickable: true,
                bulletActiveClass:
                  'swiper-pagination-bullet-active bulletClassActive mt-10',
                bulletClass: `swiper-pagination-bullet first:ml-0 first:md:ml-0 ml-4 md:ml-8 w-3 h-3 bulletClass `,
              }}
            >
              {slides}
            </Swiper>
          </div>
          <>
            <div
              onClick={() => swiperRef.current.swiper.slidePrev()}
              ref={navigationPrevRef}
              className="absolute top-1/2 left-0 z-10 -translate-y-1/2 transform cursor-pointer sm:left-0"
            >
              <LeftArrow className="scale-50 fill-launch-blue-200 sm:scale-100"></LeftArrow>
            </div>{' '}
            <div
              onClick={() => swiperRef.current.swiper.slideNext()}
              ref={navigationNextRef}
              className="absolute  top-1/2 right-0 z-10 -translate-y-1/2  transform cursor-pointer sm:right-0 "
            >
              <RightArrow className="scale-50 fill-launch-blue-200 sm:scale-100"></RightArrow>
            </div>
          </>
        </div>
        <style>{`
       .bulletClass {
         opacity: 1;
         background: #ffffff;
       }
 
       .bulletClassActive {
         opacity: 1;
         background: #00FFA3;
       }
       @media (max-width: 768px) {
       #launchExternshipProjects .swiper-pagination{
         display:none
       }
      }
       `}</style>
      </>
    )
  );
}

function Card(props) {
  const dark = props.dark;
  console.log(props.nestedData);
  // const companyLogo = getImage(props.nestedData.companyLogo.localFile);
  // const profileImage = getImage(props.nestedData.profileImage.localFile);

  return (
    <div
      className="mx-4  flex min-h-[335px] w-full flex-col items-center justify-start rounded-[50px] bg-launch-neutral-300 shadow-xl sm:h-[370px] sm:w-[300px]"
      // style={{ boxShadow: '10px 10px 100px -20px #0038C7' }
    >
      <div className="relative h-[125px]  w-full rounded-[50px] bg-launch-blue-300">
        <div className="absolute -bottom-[60px] left-0 right-0 mx-auto max-w-[200px]">
          {props.nestedData.projectImage}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center p-4 text-center">
        <h5 className="mb-4 mt-10 font-manrope font-semibold text-launch-blue-900">
          {props.nestedData.title}
        </h5>
        <div className="mb-4 flex w-max flex-col items-center justify-center rounded-[20px] bg-launch-neutral-100 p-3">
          {props.nestedData.label && (
            <div className="my-1 rounded-[10px] bg-launch-blue-400  px-2 text-[10px] font-bold">
              {props.nestedData.label}
            </div>
          )}
          <div className="">{props.nestedData.image}</div>
        </div>
      </div>
    </div>
  );
}
