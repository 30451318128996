import React from 'react';
import SwiperCore, { Grid, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import LeftArrow from '../../../../../../images/v5/Launch/Carousel-Left.svg';
import RightArrow from '../../../../../../images/v5/Launch/Carousel-Right.svg';

// import 'swiper/css/bundle';
import 'swiper/css';
import 'swiper/css/grid';

SwiperCore.use([Pagination, Grid]);

export default function CarouselV2(props) {
  const swiperRef = React.useRef(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const settings = {
    id: 'launchRecruiter',
    slidesPerView: 1,
    slidesPerGroup: 1,

    speed: 500,
    loop: true,
    grabCursor: true,
    preloadImages: true,
    autoplay: {
      delay: 5000,
      waitForTransition: true,
      disableOnInteraction: true,
    },
    allowTouchMove: true,
  };
  //

  const slides = [];
  let shuffledSlides = props.data;
  for (let i = 0; i < shuffledSlides.length; i += 1) {
    slides.push(
      <SwiperSlide className="flex justify-center sm:min-h-[500px]" key={i}>
        <Card nestedData={shuffledSlides[i]} />
      </SwiperSlide>,
    );
  }

  return (
    slides.length > 0 && (
      <>
        <div className="relative">
          <div className=" mx-8 sm:mx-10">
            <Swiper
              {...settings}
              ref={swiperRef}
              className="pb-20 sm:pt-[140px] sm:pb-0"
              pagination={{
                clickable: true,
                bulletActiveClass:
                  'swiper-pagination-bullet-active bulletClassActive mt-10',
                bulletClass: `swiper-pagination-bullet first:ml-0 first:md:ml-0 ml-4 md:ml-8 w-3 h-3 bulletClass `,
              }}
            >
              {slides}
            </Swiper>
          </div>
          <>
            <div
              onClick={() => swiperRef.current.swiper.slidePrev()}
              ref={navigationPrevRef}
              className="absolute top-1/2 left-0 z-10 -translate-y-1/2 transform cursor-pointer sm:left-0"
            >
              <LeftArrow className="scale-50 fill-launch-blue-200 sm:scale-100"></LeftArrow>
            </div>{' '}
            <div
              onClick={() => swiperRef.current.swiper.slideNext()}
              ref={navigationNextRef}
              className="absolute  top-1/2 right-0 z-10 -translate-y-1/2  transform cursor-pointer sm:right-0 "
            >
              <RightArrow className="scale-50 fill-launch-blue-200 sm:scale-100"></RightArrow>
            </div>
          </>
        </div>
        <style>{`
       .bulletClass {
         opacity: 1;
         background: #ffffff;
       }
 
       .bulletClassActive {
         opacity: 1;
         background: #00FFA3;
       }
     
       `}</style>
      </>
    )
  );
}

function Card(props) {
  const dark = props.dark;

  return (
    <div
      className=" mx-4"
      // style={{ boxShadow: '10px 10px 100px -20px #0038C7' }
    >
      <div className="text-center sm:hidden">
        <div className="mb-5">{props.nestedData.image}</div>
        <h4 className="mb-2 font-manrope font-extrabold leading-tight">
          {props.nestedData.title}
        </h4>
        <p className="">{props.nestedData.description}</p>
      </div>
      <div className="    hidden  h-[400px] w-[800px]  justify-between rounded-[50px] bg-launch-blue-900 sm:flex">
        <div className="flex w-full flex-col items-center justify-center p-5">
          <div className="pr-[100px]">
            <h2 className="mb-4 font-manrope font-semibold leading-tight">
              {props.nestedData.title}
            </h2>
            <p className="">{props.nestedData.description}</p>
          </div>
        </div>
        <div className=" -mr-10 hidden min-w-[320px] rounded-l-[100px] rounded-r-[50px] bg-launch-blue-800 p-3 sm:block">
          <div className="absolute right-[180px] -top-[140px] z-20 w-[350px]">
            {props.nestedData.image}
          </div>
        </div>
      </div>
    </div>
  );
}
