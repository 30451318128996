import React from 'react';
import SwiperCore, { Grid, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import LeftArrow from '../../../../../../images/v5/Launch/Carousel-Left.svg';
import RightArrow from '../../../../../../images/v5/Launch/Carousel-Right.svg';

// import 'swiper/css/bundle';
import { shuffle } from 'lodash';
import 'swiper/css';
import 'swiper/css/grid';

SwiperCore.use([Pagination, Grid]);

export default function CarouselV2(props) {
  const swiperRef = React.useRef(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const settings = {
    slidesPerView: 2,
    slidesPerGroup: 2,
    grid: {
      rows: 3,
      fill: 'row',
    },
    loop: true,
    spaceBetween: 14,

    breakpoints: {
      768: {
        spaceBetween: 30,

        slidesPerView: 4,
        grid: {
          rows: 2,
          fill: 'row',
        },
      },
    },
    speed: 500,
    grabCursor: true,
    preloadImages: true,
    autoplay: {
      delay: 8000,
      waitForTransition: true,
    },
    allowTouchMove: true,
  };

  const slides = [];
  let shuffledSlides = shuffle(props.data);
  for (let i = 0; i < shuffledSlides.length; i += 1) {
    slides.push(
      <SwiperSlide className="flex items-center justify-center" key={i}>
        <Card nestedData={shuffledSlides[i]} />
      </SwiperSlide>,
    );
  }

  return (
    slides.length > 0 && (
      <div className="relative">
        <div className="mx-8 sm:mx-20">
          <Swiper {...settings} ref={swiperRef}>
            {slides}
          </Swiper>
        </div>
        <>
          <div
            onClick={() => swiperRef.current.swiper.slidePrev()}
            ref={navigationPrevRef}
            className="absolute top-1/2 left-0 z-10 -translate-y-1/2 transform cursor-pointer sm:left-5"
          >
            <LeftArrow className="scale-50 fill-launch-blue-200 sm:scale-100"></LeftArrow>
          </div>{' '}
          <div
            onClick={() => swiperRef.current.swiper.slideNext()}
            ref={navigationNextRef}
            className="absolute  top-1/2 right-0 z-10 -translate-y-1/2  transform cursor-pointer sm:right-5 "
          >
            <RightArrow className="scale-50 fill-launch-blue-200 sm:scale-100"></RightArrow>
          </div>
        </>
      </div>
    )
  );
}

function Card(props) {
  const dark = props.dark;
  // const companyLogo = getImage(props.nestedData.companyLogo.localFile);
  // const profileImage = getImage(props.nestedData.profileImage.localFile);

  return (
    <div className=" flex h-[90px] w-full items-center justify-center rounded-[20px] bg-launch-neutral-100 p-4 sm:h-[118px]">
      {props.nestedData.image}
    </div>
  );
}
